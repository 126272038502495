import React, { useState, type ReactElement } from 'react'
import { Center, Heading, Text } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import OnboardingPage from '@/library/page/OnboardingPage'
import { Color, ContainerWidth } from '@/theme/theme'
import Button, { ButtonVariant } from '@/library/button/Button'
import { Graphic } from '@/library/utility/Graphic'
import { ERROR_OCTAGON_GRAPHIC } from '@/library/theme/graphics'
import { ROUTES } from '@/api/routes'
import {
  type UpdateUserOnboardingState,
  type UpdateUserOnboardingStateVariables
} from '@/graphql/__generated__/UpdateUserOnboardingState'
import { UPDATE_USER_ONBOARDING_STATE } from '@/graphql/mutations/onboarding/UpdateUserOnboardingState'
import ErrorInline from '@/library/errors/ErrorInline'
import { type GetOnboardingUser } from '@/graphql/__generated__/GetOnboardingUser'
import { GET_ONBOARDING_USER } from '@/graphql/queries/onboarding/GetOnboardingUser'
import { type ErrorWithContent } from '@/types/types'
import { ErrorCopy } from '@/utils/errorUtils'

export default function OnboardingEmailVerificationPage (): ReactElement {
  const navigate = useNavigate()
  const [skipEmailVerificationError, setSkipEmailVerificationError] = useState<ErrorWithContent | undefined>()
  const {
    data,
    loading,
    error
  } = useQuery<GetOnboardingUser>(GET_ONBOARDING_USER, { fetchPolicy: 'no-cache' })
  const [
    skipEmailVerification,
    { loading: isSkipEmailVerificationLoading }
  ] = useMutation<UpdateUserOnboardingState, UpdateUserOnboardingStateVariables>(
    UPDATE_USER_ONBOARDING_STATE,
    {
      variables: { isEmailVerificationSkipped: true },
      onCompleted: () => { navigate(ROUTES.V2_SIGN_UP_ORGANIZATION_CREATE) },
      onError: (error) => {
        setSkipEmailVerificationError({
          customContent: {
            title: ErrorCopy.SOMETHING_WENT_WRONG,
            subtitle: 'Unable to skip email verification. Please try again or contact support.'
          },
          error
        })
      }
    }
  )

  return (
    <OnboardingPage maxWidth={ContainerWidth.SMALL} isLoading={loading} error={error}>
      <Graphic src={ERROR_OCTAGON_GRAPHIC} h='95px'/>
      <Center flexDir='column' gap={1} textAlign='center'>
        <Heading>
          Confirm your email
        </Heading>
        <Text>
          We’ve sent a verification link to{' '}
          <Text as='span' color={Color.DARK_BLUE}>
            {data?.currentUser?.email}
          </Text>.
          If you don’t see an email from us, check your spam folder.
        </Text>
      </Center>
      <Button
        text='Skip this for now'
        variant={ButtonVariant.WHITE}
        onClick={() => { void skipEmailVerification() }}
        isLoading={isSkipEmailVerificationLoading}
      />
      <ErrorInline error={skipEmailVerificationError}/>
    </OnboardingPage>
  )
}
