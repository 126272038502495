import React, { type ReactElement } from 'react'
import { Divider, Flex, Heading, Text } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import DefaultCard from '@/library/card/DefaultCard'
import Button from '@/library/button/Button'
import { ROUTES } from '@/api/routes'
import RightArrowIcon from '@/library/icons/RightArrowIcon'
import { Color, IconSize } from '@/theme/theme'
import { Graphic } from '@/library/utility/Graphic'
import { V2_ALTIR_CARD } from '@/library/theme/graphics'
import { CardRow } from '@/library/layout/CardComponets'
import {
  type GetCapitalOSAccountCardData_currentUser_capitalOsConfiguration_account as Account
} from '@/graphql/__generated__/GetCapitalOSAccountCardData'
import { EMPTY_BALANCE_VALUE } from '@/utils/constants'

interface CapitalOSManagementComponentProps {
  account?: Account
}

export default function CapitalOSManageComponent ({ account }: CapitalOSManagementComponentProps): ReactElement {
  const navigate = useNavigate()
  return (
    <DefaultCard>
      <Flex flexDir='column' w='100%' gap={6}>
        <Flex flexDir='column' gap={20}>
          <Flex justifyContent='space-between' alignItems='start'>
            <Text color={Color.DARK_BLUE}>Altir Cards Overview</Text>
            <Graphic src={V2_ALTIR_CARD} h='70px'/>
          </Flex>
          <Flex flexDir='column' gap={2}>
            <CardRow>
              <Text>Credit Limit</Text>
              <Heading color={Color.DARK_BLUE}>{account?.currentLimit?.amountFormatted ?? EMPTY_BALANCE_VALUE}</Heading>
            </CardRow>
            <Divider/>
            <CardRow>
              <Text>Current Balance</Text>
              <Text color={Color.DARK_BLUE}>{account?.currentBalance?.amountFormatted ?? EMPTY_BALANCE_VALUE}</Text>
            </CardRow>
          </Flex>
        </Flex>
        <Button
          text='Manage my Altir Cards'
          afterIcon={<RightArrowIcon size={IconSize.SCHMEDIUM}/>}
          onClick={() => { navigate(ROUTES.CAPITAL_OS_CARDS) }}
        />
      </Flex>
    </DefaultCard>
  )
}
