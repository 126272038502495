import React, { useState, type ReactElement } from 'react'
import { Center, FormControl } from '@chakra-ui/react'
import Button, { ButtonVariant } from '@/library/button/Button'
import { FormInput } from '@/library/form/text/FormInput'
import AddressForm, { type AddressInput } from '@/library/form/address/AddressForm'
import { Color } from '@/theme/theme'
import { isInvalidAddress } from '@/utils/formUtils'
import { isEmpty } from '@/utils/stringUtils'
import { type ErrorWithContent } from '@/types/types'
import ErrorInline from '@/library/errors/ErrorInline'

export interface CheckRecipientDetailsFormData {
  name?: string
  address?: AddressInput | null
}

interface CheckRecipientDetailsFormProps {
  onSubmit: (state: CheckRecipientDetailsFormData) => void
  onCancel?: () => void
  isSubmissionLoading: boolean
  submissionError?: ErrorWithContent
  existingRecipientDetails: CheckRecipientDetailsFormData | null

  // Styles
  backgroundColor?: Color
}

export default function CheckRecipientDetailsForm ({
  onSubmit,
  onCancel,
  isSubmissionLoading,
  submissionError,
  existingRecipientDetails,
  backgroundColor = Color.WHITE
}: CheckRecipientDetailsFormProps): ReactElement {
  const [formState, setFormState] = useState<CheckRecipientDetailsFormData>({
    name: existingRecipientDetails?.name,
    address: existingRecipientDetails?.address
  })

  function onChange (event: React.ChangeEvent<HTMLInputElement>): void {
    const { name, value } = event.target
    updateForm(name, value)
  }

  function updateForm (name: string, value: string | boolean | AddressInput): void {
    setFormState((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }))
  }

  function isFormValid (): boolean {
    return !isEmpty(formState.name) &&
        formState.address != null && !isInvalidAddress(formState.address).isInvalid
  }

  return (
    <FormControl>
      <Center w='100%' flexDir='column' gap={8}>
        <Center w='100%' flexDir='column' gap={4}>
          <FormInput
            label='Check Recipient Name'
            fieldName='name'
            value={formState.name}
            onChange={onChange}
            placeholder='i.e. John Smith'
            isRequired={true}
            backgroundColor={backgroundColor}
          />
          <AddressForm
            formName='Check Recipient Address'
            subtext='Physical address where the recipient will receive the check'
            prepulatedAddress={formState.address}
            onUpdate={(address) => { updateForm('address', address) }}
            isRequired={true}
            backgroundColor={backgroundColor}
          />
        </Center>
        <ErrorInline error={submissionError}/>
        <Center w='100%' flexDir='column' gap={4}>
          <Button
            text='Continue'
            isLoading={isSubmissionLoading}
            onClick={() => { onSubmit(formState) }}
            isDisabled={!isFormValid()}
          />
          {
            onCancel != null &&
              <Button
                text='Cancel'
                onClick={onCancel}
                variant={ButtonVariant.WHITE_OUTLINE}
              />
          }
        </Center>
      </Center>
    </FormControl>
  )
}
