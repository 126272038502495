import React, { type ReactElement } from 'react'
import { Box, Flex } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import VerticalNavMenuItem, { NavIcon } from './menu_item/VerticalNavMenuItem'
import { getCardsMenuSubFields } from './utils'
import AltirLogo from '../logo/AltirLogo'
import NavToggleComponent from '../utility/toggle/NavCollapseComponent'
import { RouteParam, ROUTES } from '@/api/routes'
import {
  type GetNavContainerData_amplifyAccounts as AmplifyAccount,
  type GetNavContainerData_currentUser as CurrentUser
} from '@/graphql/__generated__/GetNavContainerData'
import { nonNull } from '@/utils/arrayUtils'
import { getBusinessPageRoute, routeWithParams } from '@/utils/routerUtils'
import { useAltirStore } from '@/hooks/store/useAltirStore'
import { BorderRadius, Color, Height, TransitionTime, Width, ZIndex } from '@/theme/theme'
import { getMask } from '@/utils/financialAccountUtils'

const MotionFlex = motion(Flex)

interface VerticalNavBarProps {
  currentUser?: CurrentUser | null
  amplifyAccounts: AmplifyAccount[]
  isExpanded: boolean
  onToggle: () => void
  isLoading: boolean
}

const WIDTH_VARIANT = {
  EXPANDED: {
    width: `${Width.VERTICAL_NAVBAR_EXPANDED_WIDTH_PIXELS}px`,
    maxWidth: `${Width.VERTICAL_NAVBAR_EXPANDED_WIDTH_PIXELS}px`
  },
  COLLAPSED: {
    width: `${Width.VERTICAL_NAVBAR_COLLAPSED_WIDTH_PIXELS}px`,
    maxWidth: `${Width.VERTICAL_NAVBAR_COLLAPSED_WIDTH_PIXELS}px`
  }
}

export default function VerticalNavBar ({
  currentUser,
  amplifyAccounts,
  isExpanded,
  onToggle
}: VerticalNavBarProps): ReactElement {
  const franchiseGroupId = useAltirStore((state) => state.selectedFranchiseGroupId)
  const organization = currentUser?.selectedOrganization
  const franchiseGroup = organization?.franchiseGroups?.find(
    group => Number(group.id) === franchiseGroupId
  )

  // Rollout Configurations
  const isCapitalOsEnabled = currentUser?.rolloutConfiguration?.enableCapitalOS === true

  // Authorization
  const shouldShowTransferItem = amplifyAccounts.length > 0

  const hasAccountsNeedingReLinking = franchiseGroup?.hasBrokenAccountLink === true

  // TODO (PJ): Extract into util
  const accountsMenuSubFields = amplifyAccounts.map(account => {
    return {
      text: `${String(account.name)} ${String(getMask(account.lastFour))}`,
      link: routeWithParams(
        ROUTES.ACCOUNT_DETAIL,
        [{ param: RouteParam.ACCOUNT_ID, value: String(account.accountId) }]
      )
    }
  })

  const homeMenuSubFields = nonNull(currentUser?.selectedOrganization?.franchiseGroups ?? [])
    .map(business => { return { text: business.name ?? '', link: getBusinessPageRoute(business.id) } })

  const cardsMenuSubFields = getCardsMenuSubFields(
    currentUser?.selectedOrganization?.franchiseGroups ?? [],
    { isCapitalOsEnabled }
  )
  const transfersSubFields = [
    { text: 'Recipients', link: `${ROUTES.RECIPIENTS}` },
    { text: 'Automated Transfers', link: `${ROUTES.AUTOMATED_TRANSFERS}` }
  ]

  return (
    <MotionFlex
      h='100%'
      bg={Color.WHITE}
      overflow='hidden'
      as={motion.div}
      animate={isExpanded ? WIDTH_VARIANT.EXPANDED : WIDTH_VARIANT.COLLAPSED}
      transition={{ duration: TransitionTime.NAV_TRANSITION }}
      initial={WIDTH_VARIANT.EXPANDED}
      zIndex={ZIndex.NAV_PRIMARY}
      boxShadow='0px 4px 10px rgba(0, 0, 0, 0.2)'
      borderRightRadius={BorderRadius.CARD}
    >
      <Flex justifyContent='space-between' flexDir='column' alignItems='center' gap={8} h='100%' w='100%'>
        <Flex justifyContent='start' flexDir='column' alignItems='center' w='100%' overflowY='auto'>
          <Flex
            px={isExpanded ? 7 : 0}
            alignItems='center'
            justifyContent={isExpanded ? 'start' : 'center'}
            w='100%'
            h={Height.NAVBAR}
          >
            <NavToggleComponent isExpanded={isExpanded} onToggle={onToggle}/>
          </Flex>
          <Flex flexDirection='column' px={2} overflowY='auto' flex={1} w='100%'>
            <Flex flex={1} flexDirection='column' alignItems={isExpanded ? 'start' : 'center'} gap={2}>
              <VerticalNavMenuItem
                text='Home'
                icon={NavIcon.HOME}
                route={ROUTES.HOME}
                options={homeMenuSubFields}
                isCompact={!isExpanded}
              />
              <VerticalNavMenuItem
                text='Accounts'
                icon={NavIcon.TREASURY}
                route={ROUTES.ACCOUNT}
                options={accountsMenuSubFields}
                isCompact={!isExpanded}
              />
              {/*
              Once organizations are rolled out, only expose cards if we've explicitly
              created a capitalOS config for the user.

              Eventually we will derive this via DB state, but for now we have to rely on feature flag

            */}
              {isCapitalOsEnabled &&
                <VerticalNavMenuItem
                  text='Cards'
                  icon={NavIcon.CARDS}
                  route={ROUTES.CARDS}
                  options={cardsMenuSubFields}
                  isCompact={!isExpanded}
                />
              }
              {
                shouldShowTransferItem &&
                  <VerticalNavMenuItem
                    text='Transfer'
                    icon={NavIcon.TRANSFER}
                    route={ROUTES.TRANSFER}
                    options={transfersSubFields}
                    isCompact={!isExpanded}
                  />
              }
              <VerticalNavMenuItem
                text='Loans'
                icon={NavIcon.LOANS}
                route={ROUTES.LOANS}
                isCompact={!isExpanded}
              />
              <VerticalNavMenuItem
                text='Transactions'
                icon={NavIcon.TRANSACTIONS}
                route={ROUTES.TRANSACTIONS}
                isCompact={!isExpanded}
              />
              <VerticalNavMenuItem
                text='Settings'
                icon={NavIcon.SETTINGS}
                route={ROUTES.SETTINGS}
                hasNotification={hasAccountsNeedingReLinking}
                isCompact={!isExpanded}
              />
            </Flex>
          </Flex>
        </Flex>
        <Box px={4} pb={4} w='100%'>
          <AltirLogo isExpanded={isExpanded}/>
        </Box>
      </Flex>
    </MotionFlex>
  )
}
