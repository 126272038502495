import React, { type ReactElement } from 'react'
import { Center } from '@chakra-ui/react'
import { Graphic } from './Graphic'
import MenuIconHorizontal from '../icons/MenuIconHorizontal'
import { V2_ALTIR_LOGO_BLUE_SQUARE_BOUNDING_BOX } from '@/library/theme/graphics'
import { Color } from '@/theme/theme'

interface AltirPartnerGraphicProps {
  partnerLogoSrc: string
}

export default function AltirPartnerLinkGraphic ({ partnerLogoSrc }: AltirPartnerGraphicProps): ReactElement {
  return (
    <Center gap={2}>
      <Center borderRadius='100%' bg={Color.WHITE} p={4}>
        <Graphic src={V2_ALTIR_LOGO_BLUE_SQUARE_BOUNDING_BOX} h='40px'/>
      </Center>
      <MenuIconHorizontal color={Color.DARK_GREY}/>
      <Center borderRadius='100%' bg={Color.WHITE} p={4}>
        <Graphic src={partnerLogoSrc} h='40px'/>
      </Center>
    </Center>
  )
}
