import React, { type ReactElement } from 'react'
import { Flex, Heading, Text } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import AmplifyPromoInterestCalculator from './AmplifyPromoInterestCalculator'
import { ROUTES } from '@/api/routes'
import { BorderRadius, Color, IconSize } from '@/theme/theme'
import { Graphic } from '@/library/utility/Graphic'
import { ALTIR_DOLLAR_HEART_FIGURE } from '@/library/theme/graphics'
import { type GetAmplifyAccountCardData } from '@/graphql/__generated__/GetAmplifyAccountCardData'
import Button from '@/library/button/Button'
import RightArrowIcon from '@/library/icons/RightArrowIcon'
import { AmplifyAccountApplicationStatus } from '@/graphql/__generated__/globalTypes'
import { isAmplifyApplicationActive } from '@/utils/amplifyApplicationUtils'
import { getPercentageFormatted } from '@/utils/stringUtils'

interface AmplifyAccountPromoCardProps {
  data?: GetAmplifyAccountCardData
  totalBalance?: number
}

export default function AmplifyAccountPromoCard ({
  data,
  totalBalance
}: AmplifyAccountPromoCardProps): ReactElement {
  const navigate = useNavigate()

  const apy = getPercentageFormatted(data?.amplifyAccountPromotionalOffer?.subscribedAnnualPercentageYield ?? null)
  const applicationStatus = data?.currentUser?.franchiseGroup?.amplifyAccountApplicationStatus
  const isApplicationUnderReview = applicationStatus === AmplifyAccountApplicationStatus.MANUAL_REVIEW

  return (
    <Flex w='100%' flexDir='column' gap={3}>
      <Flex
        flexDir='column'
        justifyContent='center'
        alignItems='center'
        textAlign='center'
        w='100%'
        borderRadius={BorderRadius.CARD}
        bg={Color.WHITE}
        p={8}
        gap={12}
      >
        <Flex flexDir='column' gap={6}>
          <Flex flexDir='column' gap={3}>
            <Graphic src={ALTIR_DOLLAR_HEART_FIGURE} h='150px' pt={6} pb={4}/>
            <Heading color={Color.DARK_BLUE} size='lg'>Open your<br/>Amplify Account</Heading>
            <Text>
              Activate this account to earn up to{' '}
              <Text as='span' color={Color.DARK_BLUE}>{apy}</Text>{' '}Annual Percentage Yield (APY).
              Below is a projected interest calculator:
            </Text>
          </Flex>
          <AmplifyPromoInterestCalculator
            interestRate={data?.amplifyAccountPromotionalOffer?.subscribedAnnualPercentageYield}
            totalBalance={totalBalance}
          />
        </Flex>
        <Flex flexDir='column' gap={4} w='100%'>
          <Button
            text={getCtaText(applicationStatus)}
            onClick={() => { navigate(ROUTES.AMPLIFY_APP_APPLY) }}
            afterIcon={!isApplicationUnderReview ? <RightArrowIcon size={IconSize.MEDIUM}/> : undefined}
            isDisabled={isApplicationUnderReview}
          />
          <Text fontSize='sm'>Interest rates subject to change</Text>
        </Flex>
      </Flex>
    </Flex>
  )
}

function getCtaText (applicationStatus?: AmplifyAccountApplicationStatus | null): string {
  if (
    applicationStatus != null &&
    [
      AmplifyAccountApplicationStatus.MANUAL_REVIEW,
      AmplifyAccountApplicationStatus.REJECTED
    ].includes(applicationStatus)
  ) {
    return 'Application in Review'
  } else if (isAmplifyApplicationActive(applicationStatus)) {
    return 'Finish Application'
  }

  return 'Start Application'
}
