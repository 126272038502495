import React, { type ReactElement } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { Analytics } from '@vercel/analytics/react'
import { SpeedInsights } from '@vercel/speed-insights/react'
import Auth0Provider from './auth/Auth0Provider'
import ApolloProvider from './apollo/ApolloProvider'
import IndexPage from './pages/index/IndexPage'
import '../assets/css/App.css'
import '../assets/css/fonts.css'
import LoginPage from './pages/login/LoginPage'
import TreasuryPage from './pages/treasury/TreasuryPage'
import AccountStatementPage from './pages/account_statement/AccountStatementPage'
import AmplifyApplicationPage
  from './pages/amplify_account_application/routes/apply/AmplifyApplicationPage'
import TransferPage from './pages/transfer/TransferPage'
import TransferHistoryPage from './pages/transfer_history/TransferHistoryPage'
import TermsAndConditionsPage from './pages/terms_and_conditions/TermsAndConditionsPage'
import SettingsPage from './pages/settings/SettingsPage'
import LoansPage from './pages/loans/LoansPage'
import ThemeProvider from './library/theme/ThemeProvider'
import AmplifyAccountDetailPage from './pages/account_detail/AmplifyAccountDetailPage'
import RecipientsPage from './pages/recipients/RecipientsPage'
import TransactionsPage from './pages/transactions/TransactionsPage'
import UrlNotFoundPage from './pages/not_found/UrlNotFoundPage'
import AutomatedTransfersPage from './pages/transfer_rules/TransferRulePage'
import TransferRuleCreationPage from './pages/transfer_rules/TransferRuleCreationPage'
import NotAuthorizedPage from './pages/not_authorized/NotAuthorizedPage'
import HomePage from './pages/home/HomePage'
import TreasuryBusinessPage from './pages/treasury/TreasuryBusinessPage'
import AccountsPage from './pages/accounts/AccountsPage'
import AltirContextLayout from './pages/layout/AltirContextLayout'
import NavContainerLayout from './library/nav/NavContainerLayout'
import RequireAuthenticationLayout from './pages/layout/RequireAuthenticationLayout'
import ServerErrorPage from './pages/server_error/ServerErrorPage'
import CardsPage from './pages/cards/CardsPage'
import CapitalOSPage from './pages/capital_os/CapitalOSPage'
import OnboardingUserPage from './pages/v2/sign_up/routes/user/OnboardingUserPage'
import OnboardingEmailVerificationPage
  from './pages/v2/sign_up/routes/email_verification/OnboardingEmailVerificationPage'
import OnboardingEmailVerificationVerifyPage
  from './pages/v2/sign_up/routes/email_verification/routes/verify/OnboardingEmailVerificationVerifyPage'
import OnboardingOrganizationCreatePage
  from './pages/v2/sign_up/routes/organization/routes/create/OnboardingOrganizationCreatePage'
import OnboardingOrganizationAcceptInvitePage
  from './pages/v2/sign_up/routes/organization/routes/accept/OnboardingOrganizationInviteAcceptPage'
import OnboardingBusinessPage from './pages/v2/sign_up/routes/business/OnboardingBusinessPage'
import OnboardingBusinessCreatePage from './pages/v2/sign_up/routes/business/routes/create/OnboardingBusinessCreatePage'
import OnboardingAmplifyIntroPage from './pages/v2/sign_up/routes/amplify/OnboardingAmplifyIntroPage'
import OnboardingAmplifyOverviewPage
  from './pages/v2/sign_up/routes/amplify/routes/overview/OnboardingAmplifyOverviewPage'
import OnboardingAmplifyApplyPage from './pages/v2/sign_up/routes/amplify/routes/apply/OnboardingAmplifyApplyPage'
import OnboardingProPage from './pages/v2/sign_up/routes/pro/OnboardingProPagePage'
import OnboardingStoreLocationCreatePage
  from './pages/v2/sign_up/routes/business/routes/store_location/routes/create/OnboardingStoreLocationCreatePage'
import OnboardingSuccessPage from './pages/v2/sign_up/routes/success/OnboardingSuccessPage'
// eslint-disable-next-line max-len
import OnboardingAmplifyApplyPersonConfirmationPage from './pages/v2/sign_up/routes/amplify/routes/apply/routes/person_confirmation/OnboardingAmplifyApplyPersonConfirmationPage'
import OnboardingAmplifyApplyPersonPage
  from './pages/v2/sign_up/routes/amplify/routes/apply/routes/person/OnboardingAmplifyApplyPersonPage'
import OnboardingAmplifyApplyBusinessPage
  from './pages/v2/sign_up/routes/amplify/routes/apply/routes/business/OnboardingAmplifyApplyBusinessPage'
import OnboardingAmplifyApplyUsagePage
  from './pages/v2/sign_up/routes/amplify/routes/apply/routes/usage/OnboardingAmplifyApplyUsagePage'
import OnboardingAmplifyApplyDisclosuresPage
  from './pages/v2/sign_up/routes/amplify/routes/apply/routes/disclosures/OnboardingAmplifyApplyDisclosuresPage'
import OnboardingBusinessIntroPage from './pages/v2/sign_up/routes/business/routes/intro/OnboardingBusinessIntroPage'
import AmplifyApplyPersonPage
  from './pages/amplify_account_application/routes/apply/routes/person/AmplifyApplyPersonPage'
import AmplifyApplyPersonConfirmationPage
  from './pages/amplify_account_application/routes/apply/routes/person_confirmation/AmplifyApplyPersonConfirmationPage'
import AmplifyApplyBusinessPage
  from './pages/amplify_account_application/routes/apply/routes/business/AmplifyApplyBusinessPage'
import AmplifyApplyUsagePage from './pages/amplify_account_application/routes/apply/routes/usage/AmplifyApplyUsagePage'
import AmplifyApplyDisclosuresPage
  from './pages/amplify_account_application/routes/apply/routes/disclosures/AmplifyApplyDisclosuresPage'
import AmplifyApplicationOverviewPage
  from './pages/amplify_account_application/routes/overview/AmplifyApplicationOverviewPage'
import OnboardingLayout from './pages/layout/OnboardingLayout'
import GlobalLayout from './pages/layout/GlobalLayout'
import AmplifyApplyOwnershipPage from
  './pages/amplify_account_application/routes/apply/routes/ownership/AmplifyApplyOwnershipPage'
import OnboardingAmplifyApplyOwnershipPage
  from './pages/v2/sign_up/routes/amplify/routes/apply/routes/ownership/OnboardingAmplifyApplyOwnershipPage'
import TransferContextLayout from './pages/layout/TransferContextLayout'
import ChecksPage from './pages/checks/ChecksPage'
import RecipientCreationPage from './pages/recipients/routes/creation/RecipientCreationPage'
import ResetStatePage from './pages/reset_state/ResetStatePage'
import { getVercelEnvironment } from '@/utils/envUtils'
import { ROUTES } from '@/api/routes'

export default function App (): ReactElement<string, string> {
  return (
    <>
      <Auth0Provider>
        <ApolloProvider>
          <ThemeProvider>
            <Router>
              <Routes>
                <Route Component={GlobalLayout}>
                  <Route Component={RequireAuthenticationLayout}>
                    <Route Component={AltirContextLayout}>
                      <Route Component={NavContainerLayout}>
                        <Route path={ROUTES.ACCOUNT} Component={AccountsPage}/>
                        <Route path={ROUTES.ACCOUNT_DETAIL} Component={AmplifyAccountDetailPage}/>
                        <Route path={ROUTES.CAPITAL_OS_CARDS} Component={CapitalOSPage}/>
                        <Route path={ROUTES.CARDS} Component={CardsPage}/>
                        <Route path={ROUTES.CARDS_DETAIL} Component={AccountStatementPage}/>
                        <Route path={ROUTES.HOME} Component={HomePage}/>
                        <Route path={ROUTES.LOANS} Component={LoansPage}/>
                        <Route path={ROUTES.INDEX} Component={IndexPage}/>
                        <Route path={ROUTES.RECIPIENTS} Component={RecipientsPage}/>
                        <Route path={ROUTES.RECIPIENTS_CREATE} Component={RecipientCreationPage}/>
                        <Route path={ROUTES.SETTINGS} Component={SettingsPage}/>
                        <Route path={ROUTES.TRANSACTIONS} Component={TransactionsPage}/>
                        <Route path={ROUTES.TERMS_AND_CONDITIONS} Component={TermsAndConditionsPage}/>
                        <Route path={ROUTES.TREASURY} Component={TreasuryPage}/>
                        <Route path={ROUTES.TREASURY_BUSINESS} Component={TreasuryBusinessPage}/>
                        <Route path={ROUTES.TREASURY_DETAIL} Component={AccountStatementPage}/>

                        {/* Amplify Pages */}
                        <Route path={ROUTES.ACCOUNT_DETAIL} Component={AmplifyAccountDetailPage}/>
                        <Route path={ROUTES.CHECKS} Component={ChecksPage}/>

                        {/* Transfer Pages */}
                        <Route Component={TransferContextLayout}>
                          <Route path={ROUTES.AUTOMATED_TRANSFERS} Component={AutomatedTransfersPage}/>
                          <Route path={ROUTES.CREATE_AUTOMATED_TRANSFER_RULE} Component={TransferRuleCreationPage}/>
                          <Route path={ROUTES.TRANSFER} Component={TransferPage}/>
                          <Route path={ROUTES.TRANSFER_HISTORY} Component={TransferHistoryPage}/>
                        </Route>
                      </Route>
                      {/* Should not have Nav Container */}
                      <Route path={ROUTES.AMPLIFY_APP_OVERVIEW} Component={AmplifyApplicationOverviewPage}/>
                      <Route path={ROUTES.AMPLIFY_APP_APPLY} Component={AmplifyApplicationPage}/>
                      <Route path={ROUTES.AMPLIFY_APP_OWNERSHIP} Component={AmplifyApplyOwnershipPage}/>
                      <Route path={ROUTES.AMPLIFY_APP_PERSON} Component={AmplifyApplyPersonPage}/>
                      <Route path={ROUTES.AMPLIFY_APP_PERSON_CONFIRMATION}
                        Component={AmplifyApplyPersonConfirmationPage}
                      />
                      <Route path={ROUTES.AMPLIFY_APP_BUSINESS} Component={AmplifyApplyBusinessPage}/>
                      <Route path={ROUTES.AMPLIFY_APP_USAGE} Component={AmplifyApplyUsagePage}/>
                      <Route
                        path={ROUTES.AMPLIFY_APP_DISCLOSURES}
                        Component={AmplifyApplyDisclosuresPage}
                      />
                    </Route>

                    {/*  Paths without access to Altir Store  */}
                    <Route path={ROUTES.V2_SIGN_UP} Component={OnboardingLayout}>
                      <Route path={ROUTES.V2_SIGN_UP_USER} Component={OnboardingUserPage}/>
                      <Route path={ROUTES.V2_SIGN_UP_EMAIL_VERIFICATION} Component={OnboardingEmailVerificationPage}/>
                      <Route
                        path={ROUTES.V2_SIGN_UP_EMAIL_VERIFICATION_VERIFY}
                        Component={OnboardingEmailVerificationVerifyPage}
                      />
                      <Route path={ROUTES.V2_SIGN_UP_ORGANIZATION_CREATE} Component={OnboardingOrganizationCreatePage}/>
                      <Route
                        path={ROUTES.V2_SIGN_UP_ORGANIZATION_ACCEPT}
                        Component={OnboardingOrganizationAcceptInvitePage}
                      />
                      <Route path={ROUTES.V2_SIGN_UP_BUSINESS} Component={OnboardingBusinessPage}/>
                      <Route path={ROUTES.V2_SIGN_UP_BUSINESS_INTRO} Component={OnboardingBusinessIntroPage}/>
                      <Route path={ROUTES.V2_SIGN_UP_BUSINESS_CREATE} Component={OnboardingBusinessCreatePage}/>
                      <Route
                        path={ROUTES.V2_SIGN_UP_BUSINESS_CREATE_STORE_LOCATION}
                        Component={OnboardingStoreLocationCreatePage}
                      />
                      <Route path={ROUTES.V2_SIGN_UP_AMPLIFY_INTRO} Component={OnboardingAmplifyIntroPage}/>
                      <Route path={ROUTES.V2_SIGN_UP_AMPLIFY_OVERVIEW} Component={OnboardingAmplifyOverviewPage}/>
                      <Route path={ROUTES.V2_SIGN_UP_AMPLIFY_APP} Component={OnboardingAmplifyApplyPage}/>
                      <Route
                        path={ROUTES.V2_SIGN_UP_AMPLIFY_APP_OWNERSHIP}
                        Component={OnboardingAmplifyApplyOwnershipPage}
                      />
                      <Route path={ROUTES.V2_SIGN_UP_AMPLIFY_APP_PERSON} Component={OnboardingAmplifyApplyPersonPage}/>
                      <Route path={ROUTES.V2_SIGN_UP_AMPLIFY_APP_PERSON_CONFIRMATION}
                        Component={OnboardingAmplifyApplyPersonConfirmationPage}
                      />
                      <Route
                        path={ROUTES.V2_SIGN_UP_AMPLIFY_APP_BUSINESS}
                        Component={OnboardingAmplifyApplyBusinessPage}
                      />
                      <Route path={ROUTES.V2_SIGN_UP_AMPLIFY_APP_USAGE} Component={OnboardingAmplifyApplyUsagePage}/>
                      <Route
                        path={ROUTES.V2_SIGN_UP_AMPLIFY_APP_DISCLOSURES}
                        Component={OnboardingAmplifyApplyDisclosuresPage}
                      />

                      <Route path={ROUTES.V2_SIGN_UP_PRO} Component={OnboardingProPage}/>
                      <Route path={ROUTES.V2_SIGN_UP_SUCCESS} Component={OnboardingSuccessPage}/>
                    </Route>
                  </Route>

                  {/* Paths without Authentication Requirement */}
                  <Route path={ROUTES.LOG_IN} Component={LoginPage}/>

                  {/* Error State */}
                  <Route path={ROUTES.ERROR} Component={ServerErrorPage}/>
                  <Route path={ROUTES.NOT_AUTHORIZED} Component={NotAuthorizedPage}/>
                  <Route path={ROUTES.NOT_FOUND} Component={UrlNotFoundPage}/>
                  <Route path={ROUTES.RESET_STATE} Component={ResetStatePage}/>
                  {/*
                    This route serves as a catch-all that directs to the 404 page. All new routes should be
                    added above it.
                  */}
                  <Route path='*' Component={UrlNotFoundPage}/>
                </Route>
              </Routes>
            </Router>
          </ThemeProvider>
        </ApolloProvider>
      </Auth0Provider>
      <Analytics mode={getVercelEnvironment()}/>
      <SpeedInsights/>
    </>
  )
}
