import React, { useState, type ReactElement } from 'react'
import { Center, Heading, Text } from '@chakra-ui/react'
import { useMutation } from '@apollo/client'
import CheckRecipientDetailsForm, {
  type CheckRecipientDetailsFormData
} from './CheckRecipientDetailsForm'
import { ModalComponent } from '@/library/modal/ModalComponent'
import { type UpdateCounterparty, type UpdateCounterpartyVariables } from '@/graphql/__generated__/UpdateCounterparty'
import { UPDATE_COUNTERPARTY } from '@/graphql/mutations/financial_accounts/UpdateCounterparty'
import { type ErrorWithContent } from '@/types/types'
import { addressInputToAddressUnderscoreInput } from '@/utils/addressUtils'
import { ErrorCopy } from '@/utils/errorUtils'

interface UpdateCheckRecipientDetailsModalProps {
  isOpen: boolean
  onClose: () => void
  counterpartyId: string | null
  existingRecipientDetails: CheckRecipientDetailsFormData | null
  onSuccess: (formData: CheckRecipientDetailsFormData) => void
}

export default function UpdateCheckRecipientDetailsModal ({
  isOpen,
  onClose,
  counterpartyId,
  existingRecipientDetails,
  onSuccess
}: UpdateCheckRecipientDetailsModalProps): ReactElement {
  const [submissionError, setSubmissionError] = useState<ErrorWithContent>()
  const [
    updateCounterparty,
    { loading }
  ] = useMutation<UpdateCounterparty, UpdateCounterpartyVariables>(
    UPDATE_COUNTERPARTY,
    {
      onError: (error) => {
        setSubmissionError({ error })
      }
    }
  )

  function handleFormSubmission (formData: CheckRecipientDetailsFormData): void {
    const formattedAddress = addressInputToAddressUnderscoreInput(formData.address)
    if (counterpartyId == null) {
      setSubmissionError({ error: Error() })
      return
    } else if (formData.name == null) {
      setSubmissionError({
        error: Error(),
        customContent: {
          title: ErrorCopy.FORM_UNABLE_TO_SUBMIT,
          subtitle: 'Please ensure the recipient\'s name is valid.'
        }
      })
      return
    } else if (formattedAddress == null) {
      setSubmissionError({
        error: Error(),
        customContent: {
          title: ErrorCopy.FORM_UNABLE_TO_SUBMIT,
          subtitle: 'Please ensure the recipient\'s address is valid.'
        }
      })
      return
    }

    void updateCounterparty({
      variables: {
        counterpartyId,
        checkInfo: {
          name: formData.name,
          address: formattedAddress
        }
      }
    }).then(() => {
      onSuccess(formData)
      onClose()
    })
  }

  const { title, subtitle } = getHeaderContent(existingRecipientDetails != null)

  return (
    <ModalComponent isOpen={isOpen} onClose={onClose} size='lg'>
      <Center w='100%' flexDir='column' gap={8}>
        <Center w='100%' flexDir='column' gap={1} textAlign='center'>
          <Heading>{title}</Heading>
          {subtitle != null && <Text>{subtitle}</Text>}
        </Center>
        <CheckRecipientDetailsForm
          onSubmit={handleFormSubmission}
          submissionError={submissionError}
          isSubmissionLoading={loading}
          existingRecipientDetails={existingRecipientDetails}
        />
      </Center>
    </ModalComponent>
  )
}

function getHeaderContent (hasExistingDetails: boolean): { title: string, subtitle?: string } {
  if (hasExistingDetails) {
    return { title: 'Edit Recipient Details' }
  }

  return {
    title: 'Add Recipient Details',
    subtitle: 'We need additional information to send a check to this recipient. '
  }
}
