import React, { type ReactElement } from 'react'
import { Flex } from '@chakra-ui/react'
import { Graphic } from '../utility/Graphic'
import { V2_ALTIR_LOGO_GREY } from '../theme/graphics'

export interface AltirLogoProps {
  isExpanded: boolean
}

export default function AltirLogo ({ isExpanded }: AltirLogoProps): ReactElement {
  return (
    <Flex width='100%' justifyContent={isExpanded ? 'start' : 'center'}>
      <Graphic src={V2_ALTIR_LOGO_GREY} h='16px'/>
    </Flex>
  )
}
