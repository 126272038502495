import React, { useState, type ReactElement } from 'react'
import { Center, Heading, Text } from '@chakra-ui/react'
import { useMutation } from '@apollo/client'
import CheckEnablementConfirmationModal from './CheckEnablementConfirmationModal'
import { BorderRadius, Color, ContainerWidth } from '@/theme/theme'
import Button from '@/library/button/Button'
import RightArrowIcon from '@/library/icons/RightArrowIcon'
import { Graphic } from '@/library/utility/Graphic'
import { CHECK_SEND_DOLLAR_SIGN } from '@/library/theme/graphics'
import { useAltirStore } from '@/hooks/store/useAltirStore'
import {
  type RequestCheckIssuanceAccess,
  type RequestCheckIssuanceAccessVariables
} from '@/graphql/__generated__/RequestCheckIssuanceAccess'
import { REQUEST_CHECK_ISSUANCE_ACCESS } from '@/graphql/mutations/checks/RequestCheckAccess'
import ErrorInline from '@/library/errors/ErrorInline'

interface CheckEnablementComponentProps {
  isEnablementRequested: boolean
  onSuccessfulRequest: () => void
}

const CHECK_SEND_DESCRIPTION = `
    Make a transfer to a recipient using Check Send, and we will mail a physical check to them on your behalf.
`

export default function CheckEnablementComponent ({
  isEnablementRequested,
  onSuccessfulRequest
}: CheckEnablementComponentProps): ReactElement {
  const selectedOrganizationId = useAltirStore(state => state.selectedOrganizationState.selectedOrganizationId)
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)

  const [
    requestCheckIssuanceAccess,
    { loading, error }
  ] = useMutation<RequestCheckIssuanceAccess, RequestCheckIssuanceAccessVariables>(
    REQUEST_CHECK_ISSUANCE_ACCESS,
    {
      variables: { organizationId: selectedOrganizationId },
      onCompleted: () => {
        onSuccessfulRequest()
        setIsConfirmationModalOpen(true)
      }
    }
  )

  function handleRequestAccessButtonClick (): void {
    void requestCheckIssuanceAccess()
  }

  return (
    <Center w='100%' pt={2}>
      <CheckEnablementConfirmationModal
        isOpen={isConfirmationModalOpen}
        onClose={() => { setIsConfirmationModalOpen(false) }}
      />
      <Center
        w='100%'
        flexDir='column'
        p={6}
        gap={4}
        bg={Color.WHITE}
        borderRadius={BorderRadius.CARD}
      >
        <Graphic src={CHECK_SEND_DOLLAR_SIGN} h='42px'/>
        <Center w='100%' flexDir='column' gap={1}>
          <Heading size='md' textAlign='center'>Pay your bills with a check.</Heading>
          {
            !isEnablementRequested &&
            <Text
              textAlign='center'
              maxW={ContainerWidth.SMALL}
            >
                {CHECK_SEND_DESCRIPTION}
            </Text>
        }
        </Center>
        <Button
          text={isEnablementRequested ? 'Access Requested' : 'Request Access'}
          width='auto'
          isDisabled={isEnablementRequested}
          afterIcon={isEnablementRequested ? undefined : <RightArrowIcon/>}
          onClick={() => { !isEnablementRequested && handleRequestAccessButtonClick() }}
          isLoading={loading}
        />
        <ErrorInline error={error}/>
      </Center>
    </Center>
  )
}
