import React, { useState, type ReactElement } from 'react'
import { Divider, Flex, Heading, Link, Text, useToast } from '@chakra-ui/react'
import { useMutation } from '@apollo/client'
import { Event } from 'metrics/metrics'
import LoanDataPointComponent from './LoanDataPointComponent'
import { BorderRadius, Color, ContainerWidth } from '@/theme/theme'
import ErrorInline from '@/library/errors/ErrorInline'
import { Graphic } from '@/library/utility/Graphic'
import { DOLLAR_HEART } from '@/library/theme/graphics'
import Button, { ButtonVariant } from '@/library/button/Button'
import { REQUEST_LOAN_OFFERS } from '@/graphql/mutations/RequestLoanOffers'
import {
  type RequestLoanOffersVariables,
  type RequestLoanOffers
} from '@/graphql/__generated__/RequestLoanOffers'
import { getErrorToast } from '@/utils/toastUtils'

export interface LoanIntroComponentProps {
  onNextStep: () => void
  franchiseGroupId: number
  organizationId: string | null
}
const LOAN_AMOUNT = '$25K - $5M'

export default function LoanIntroComponent (
  {
    onNextStep,
    franchiseGroupId,
    organizationId
  }: LoanIntroComponentProps
): ReactElement {
  const [
    requestLoanOffers,
    { loading: isLoanRequestLoading }
  ] = useMutation<RequestLoanOffers, RequestLoanOffersVariables>(REQUEST_LOAN_OFFERS)
  const [error, setError] = useState<Error>()
  const toast = useToast()

  function handleRequestLoans (): void {
    if (organizationId == null) {
      toast(getErrorToast('Unable to request loan information'))
      return
    }
    void requestLoanOffers({
      variables: { organizationId }
    })
      .then(onNextStep)
      .catch((e) => { setError(e) })
  }

  return (
    <Flex w='100%' justifyContent='center' alignItems='center'>
      <Flex maxW={ContainerWidth.EXTRA_LARGE} flexDir='column' gap={4}>
        <Flex
          flexDirection='column'
          justifyContent='center'
          alignItems='center'
          bg={Color.BRIGHT_BLUE}
          borderRadius={BorderRadius.CARD}
          p={12}
          gap={10}
          w='100%'
        >
          <Graphic w='auto' h='80px' mx='auto' src={DOLLAR_HEART}/>
          <Flex flexDirection='column' textAlign='center' gap={2}>
            <Heading color={Color.WHITE} size='lg'>Cash to run your business, grow, and more.</Heading>
            <Heading color={Color.WHITE} size='sm'>Explore lending options for your capital needs.</Heading>
          </Flex>
          <Heading color={Color.WHITE}>{LOAN_AMOUNT}</Heading>
          <Flex
            borderRadius={BorderRadius.CARD}
            border={`1px solid ${Color.WHITE}`}
            py={4}
            px={4}
            minW='80%'
            gap={2}
            flexDir='column'
          >
            <LoanDataPointComponent description='Amount' stat={LOAN_AMOUNT}/>
            <Divider borderColor={Color.WHITE}/>
            <LoanDataPointComponent description='Interest Rate' stat='Starting at 12% APR'/>
            <Divider borderColor={Color.WHITE}/>
            <LoanDataPointComponent description='Loan Type' stat='Line of Credit or Term Loan'/>
          </Flex>
          <Flex flexDir='column' w='100%' justifyContent='center' alignItems='center' gap={4}>
            <Button
              text='View Options'
              variant={ButtonVariant.WHITE}
              width='360px'
              onClick={handleRequestLoans}
              isLoading={isLoanRequestLoading}
              onClickEventType={Event.LOAN_PORTAL_ENTRY_CLICK}
            />
            <Text color={Color.WHITE} fontSize='sm'>
              By hitting “View my Options”, you agree to our disclosures.{' '}
              <Link as='span' textDecoration='underline' href='TODO' isExternal>
                See Disclosures
              </Link>
            </Text>
          </Flex>
        </Flex>
        <ErrorInline error={error}/>
      </Flex>
    </Flex>
  )
}
