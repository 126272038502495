import React, { type ReactElement } from 'react'
import { Flex, Heading, Text } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import CapitalOSFeatureRow from './CapitalOSFeatureRow'
import { BorderRadius, Color, FontWeight, IconSize } from '@/theme/theme'
import { V2_ALTIR_CARD_DOUBLE } from '@/library/theme/graphics'
import { Graphic } from '@/library/utility/Graphic'
import Button from '@/library/button/Button'
import { RouteName, ROUTES } from '@/api/routes'
import RightArrowIcon from '@/library/icons/RightArrowIcon'
import { CapitalOSAccountStatus } from '@/graphql/__generated__/globalTypes'
import BusinessSelectorPopover from '@/library/nav/BusinessSelectorPopover'

interface CapitalOSPromoCardComponentProps {
  accountStatus?: CapitalOSAccountStatus
  isOrganizationsEnabled: boolean
}

export default function CapitalOSPromoCardComponent (
  { accountStatus, isOrganizationsEnabled }: CapitalOSPromoCardComponentProps
): ReactElement {
  const navigate = useNavigate()

  const ctaButton = <Button
    text={getCTAText(accountStatus)}
    onClick={() => { navigate(ROUTES.CAPITAL_OS_CARDS) }}
    afterIcon={<RightArrowIcon size={IconSize.SCHMEDIUM}/>}
    width='auto'
                    />
  const CTA =
    <BusinessSelectorPopover
      quickActionRoute={`/${RouteName.CAPTIAL_OS_CARDS}`}
      isAmplifyRequired={false}
      appendBusinessIdToRoute={true}
    >
      {ctaButton}
    </BusinessSelectorPopover>
  return (
    <Flex
      flexDir='column'
      justifyContent='center'
      alignItems='center'
      textAlign='center'
      p={6}
      gap={8}
      border={`1.25px solid ${String(Color.BRIGHT_BLUE)}`}
      bg={Color.LIGHT_BLUE}
      borderRadius={BorderRadius.CARD}
    >
      <Graphic src={V2_ALTIR_CARD_DOUBLE} h='200px'/>
      <Heading color={Color.DARK_BLUE} size='lg' maxW={96}>
        Get{' '}<Heading as='span' size='lg' color={Color.BRIGHT_BLUE}>1% Cash Back</Heading>{' '}
        with an Altir Card
      </Heading>
      <Flex
        flexDir='column'
        gap={2}
        border={`1px solid ${String(Color.DARK_BLUE)}`}
        borderRadius={BorderRadius.CARD}
        p={4}
      >
        <CapitalOSFeatureRow text='Seamlessly synchronized to QuickBooks to save time and avoid human error'/>
        <CapitalOSFeatureRow text='Smart controls and automated alerts to issue team cards with peace of mind'/>
        <CapitalOSFeatureRow
          text='Credit limit for your team to fund anything from inventory to remodels'
        />
      </Flex>
      {CTA}
      <Text color={Color.DARK_BLUE} fontWeight={FontWeight.NORMAL} fontSize='xs'>
        Altir partners with CapitalOS and First Internet Bank of Indiana,
        Member FDIC, for Altir Card. Cards are issued by First Internet Bank of Indiana,
        pursuant to a license from Visa Inc.
      </Text>
    </Flex>
  )
}

function getCTAText (status?: CapitalOSAccountStatus): string {
  if (
    status != null &&
    [
      CapitalOSAccountStatus.PENDING,
      CapitalOSAccountStatus.PROCESSING,
      CapitalOSAccountStatus.ENGAGED
    ].includes(status)
  ) return 'Continue Application'

  return 'Start Application'
}
