import React, { type ReactElement } from 'react'
import { Flex, Heading, ModalBody, Text } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '@/api/routes'
import { Graphic } from '@/library/utility/Graphic'
import { V2_ALTIR_LOGO_BLUE_GRADIENT_SPARKLE } from '@/library/theme/graphics'
import Button, { ButtonVariant } from '@/library/button/Button'
import TransferIcon from '@/library/icons/TransferIcon'
import { BorderRadius, Color, FontWeight } from '@/theme/theme'

export default function SuccessContent (): ReactElement {
  const navigate = useNavigate()
  return (
    <Flex>
      <ModalBody
        backgroundColor={Color.LIGHT_GREY}
        borderRadius={BorderRadius.CARD}
        py={10}
        justifyContent='center'
        alignItems='center'
        display='flex'
        flexDir='column'
      >
        <Graphic
          w='auto'
          h='78px'
          src={V2_ALTIR_LOGO_BLUE_GRADIENT_SPARKLE}
        />
        <Flex
          alignItems='center'
          textAlign='center'
          flexDirection='column'
          my={6}
          gap={2}
        >
          <Heading color={Color.DARK_BLUE} fontWeight={FontWeight.MEDIUM} size='lg'>
            Your application has been approved!
          </Heading>
          <Text px={10}>
            You can now transfer into your Amplify Account. You can access these funds at any time.
          </Text>
        </Flex>
        <Flex w='100%' mb={4}>
          <Button
            text='Make a Transfer'
            onClick={() => { navigate(ROUTES.TRANSFER) }}
            beforeIcon={<TransferIcon/>}
          />
        </Flex>
        <Button
          text='Done'
          onClick={() => { navigate(ROUTES.TREASURY) }}
          variant={ButtonVariant.WHITE}
        />
      </ModalBody>
    </Flex>
  )
}
