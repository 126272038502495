import React, { type ReactElement } from 'react'
import { Center, Heading, Text } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import OnboardingPage from '@/library/page/OnboardingPage'
import { DisclosureText } from '@/library/legal/disclosureText'
import { Graphic } from '@/library/utility/Graphic'
import Button, { ButtonVariant } from '@/library/button/Button'
import { ROUTES } from '@/api/routes'
import { THREE_TWO_FIVE_PERCENT_BANNER } from '@/library/theme/graphics'
import OnboardingDisclosureFooter from '@/library/legal/OnboardingDisclosureFooter'
import { useSkipAmplifyApplicationCallToAction } from '@/hooks/onboarding/useSkipOnboardingAmplifyAppCallToAction'
import { ContainerWidth } from '@/theme/theme'

export default function OnboardingAmplifyIntroPage (): ReactElement {
  const navigate = useNavigate()
  const skip = useSkipAmplifyApplicationCallToAction()

  function onSubmit (): void {
    navigate(ROUTES.V2_SIGN_UP_AMPLIFY_OVERVIEW)
  }

  return (
    <OnboardingPage maxWidth={ContainerWidth.LARGE} callToAction={skip}>
      <Center flexDir='column' gap={6} w='100%'>
        <Center flexDir='column' gap={1} textAlign='center' w='100%'>
          <Heading>
            Now, set up an Amplify Account
          </Heading>
          <Text>
            {DisclosureText.ALTIR_CHECKING_ACCOUNT_DESCRIPTION}
          </Text>
        </Center>
        <Graphic src={THREE_TWO_FIVE_PERCENT_BANNER} h='200px'/>
        <Center flexDir='column' gap={4} w='75%'>
          <Button text='Continue' onClick={onSubmit}/>
          <Button text='Skip This' onClick={skip.callToActionOnClick} variant={ButtonVariant.WHITE}/>
        </Center>
      </Center>
      <OnboardingDisclosureFooter/>
    </OnboardingPage>
  )
}
